.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form__password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form__password {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.form__password-field {
  width: 100%;
  height: 32px;
  padding-inline: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.form__password-field:focus-visible {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.form__error {
  color: red;
}

.form__reset {
  color: #1890ff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin-inline: auto;
}

.form__button {
  color: white;
  background-color: #1890ff;
  border: none;
  cursor: pointer;
  width: 30%;
  height: 32px;
  padding-inline: 10px;
  border-radius: 4px;
  margin-inline: auto;
}

.form__resend {
  border: none;
  background-color: transparent;
  color: black;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
