.analytics-dashboard .analytics-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 30px;
}
.dropdown-container {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: #ffffff;
  color: #6c6c6c;
  font-size: 14px;
  padding: 10px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.dropdown-container .react-datepicker {
  right: 120%;
}

.dropdown-container .item.active .react-datepicker {
  right: 50%;
}

.dropdown-container:not(:last-of-type) {
  margin-right: 15px;
}

.dropdown-container .dropdown-icon {
  height: 14px;
  fill: #4a4a4a;
  margin-left: 18px;
  cursor: pointer;
}

.dropdown-container .dropdown-menu {
  position: absolute;
  z-index: 1;
  top: 111%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 gray;
  padding: 10px;
  width: -webkit-fill-available;
  left: 0;
}

.dropdown-container .dropdown-menu .item {
  cursor: pointer;
}

.dropdown-container .dropdown-menu .item:not(:last-of-type) {
  margin-bottom: 20px;
}

.analytics-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.analytics-header .title {
  color: #000;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.analytics-header .title span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.analytics-header .operations {
  display: -webkit-flex;
  display: flex;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.35;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.analytics-header .secondary {
  color: #000;
  margin-right: 5px;
}
.analytics-header .item {
  padding: 8px 10px;
  border-radius: 10px;
}
.analytics-header .active {
  background-color: #e9e9e9;
}
.analytics-header .custom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.analytics-header .custom .custom-icon {
  height: 14px;
  fill: var(--color-primary01);
  margin-left: 18px;
  -webkit-align-self: center;
          align-self: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.analytics-header .custom .open-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.analyticsDataPerPeriod-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.middle-cards-container {
  width: 70%;
  margin-right: 25px;
}

.line-chart-card,
.pie-chart-card {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: #fff;
  padding: 25px;
  margin-bottom: 30px;
}

.line-chart-card .label {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;
}

.pie-chart-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 6rem;
  gap: 6rem;
}

.pie-chart-container .chart-container {
  width: 250px;
}

.pie-chart-container .chart-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6rem;
  -webkit-column-gap: 6rem;
          column-gap: 6rem;
}

.analyticsTotalCounts-container {
  width: 30%;
}

.total-card {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: #fff;
  padding: 25px;
  margin-bottom: 30px;
}

.total-card .total-enrollments {
  margin-bottom: 10px;
}

.total-card .total-users-count {
  color: #4a4a4a;
  font-size: 17px;
  font-weight: bold;
}

.total-card .total-enrollments-count {
  color: #4a90e2;
  font-size: 55px;
  font-weight: bold;
  line-height: 0.7;
  margin-right: 5px;
}

.total-card .total-enrollments-label {
  color: #979797;
  font-size: 16px;
  margin: 0;
}

.completion-cards-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.completion-rate-card {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 25px;
  width: 48%;
  margin-bottom: 30px;
}

.completion-rate-card .course-rate-item p {
  margin: 0;
}

.completion-rate-card .title {
  margin: 0;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.completion-rate-card .rates-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.completion-rate-card .course-rate-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 0;
}

.completion-rate-card .course-rate-item:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}

.completion-rate-card:nth-child(odd) {
  margin-right: 25px;
}

.completion-rate-card .rate-container {
  text-align: right;
}

.completion-rate-card .rate-container .rate {
  color: #24a148;
}

.completion-rate-card .rate-container.lowest .rate {
  color: #da1e28;
}

.completion-rate-card .rate-container.count .rate {
  color: #4a4a4a;
}

.course-rate-item .name {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.course-rate-item .link {
  color: #4a90e2;
  font-size: 14px;
  text-decoration: underline;
}

.rate-container .rate {
  color: #24a148;
  font-size: 16px;
  font-weight: bold;
}

.rate-container .provider {
  color: #c4c4c4;
  font-size: 14px;
}

.analytics-tabs {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.analytics-tabs .analytics-tab-item {
  border-radius: 50px;
  background-color: #c4c4c4;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 12px 30px;
  cursor: pointer;
}

.analytics-tabs .analytics-tab-item.active {
  background-color: #4a90e2;
}

.analytics-tabs .analytics-tab-item:not(:last-child) {
  margin-right: 25px;
}

.total-card--newCreation .card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 25px;
}

.total-card--newCreation .total-enrollments-count {
  color: #000;
}

.total-card--newCreation .card-footer {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
}

.total-card--newCreation .footer-item {
  line-height: 1.2;
}

.total-card--newCreation .footer-item .title {
  color: #979797;
  font-size: 16px;
}

.total-card--newCreation .footer-item .value {
  font-size: 36px;
  text-align: center;
}

.footer-item--published .value {
  color: #24a148;
}
.footer-item--unpublished .value {
  color: #ff832b;
}

.total-card--newCreation .footer-item:first-of-type {
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}

.total-card--newCreation .footer-item:first-of-type::after {
  content: '';
  display: inline-block;
  height: 80%;
  width: 2px;
  background-color: lightgray;
  position: absolute;
  right: 0;
  bottom: 0;
}

.total-card--newCreation .footer-item p {
  margin: 0;
}

.total-performance-card .title {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 12px;
}

.total-performance-card .content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.total-performance-card .legends-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.total-performance-card .ant-select {
  margin-bottom: 1rem;
  width: 140px !important;
}
.total-performance-card .ant-select .ant-select-selection {
  height: 42px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.ant-select .ant-select-selection .ant-select-selection__rendered {
  line-height: 2;
}

.card_filter-title-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

/* Age Distribuation Card */
.content-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.age_distribuation-progress {
  display: grid;
  grid-template-columns: 100px 1fr 50px;
  -webkit-align-items: center;
          align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}
.age_distribuation-progress p,
.age_distribuation-progress span {
  margin: 0;
  text-align: center;
}

.age-role-progressbar {
  width: 100%;
  height: 5px;
  background-color: #c4d9f2;
  border-radius: 3px;
  position: relative;
}
.progressbar {
  background-color: #4a90e2;
  border-radius: 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  -webkit-transition: 1s ease 0.3s;
  transition: 1s ease 0.3s;
  padding: 3px;
}

/* /////////////// */

.chart-legend {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.chart-legend:first-of-type {
  margin-bottom: 30px;
}

.chart-legend .value {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.chart-legend .label {
  color: #4a4a4a;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.chart-legend .color-code {
  display: inline-block;
  height: 4px;
  width: 20px;
  border-radius: 2px;
  margin-bottom: 5px;
}

.dougnut-chart {
  width: 60%;
}

.gender-distribution-card {
  position: relative;
}

.pie-chart {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 4px;
  margin-bottom: 20px;
}

.pie-chart-info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.pie-chart-info img {
  max-width: 100%;
}

.pie-chart-info .icon {
  width: 30px;
  height: 30px;
}

.pie-chart-info .info-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.pie-chart-info .info-item:first-of-type {
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}

.pie-chart-info .info-item:first-of-type::after {
  content: '';
  display: inline-block;
  height: 80%;
  width: 2px;
  background-color: lightgray;
  position: absolute;
  right: 0;
  bottom: 0;
}

.pie-chart-info label {
  color: #9b9b9b;
  font-size: 12px;
}

.pie-chart-info .percent {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
}

.form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.form__password-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.form__password {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.form__password-field {
  width: 100%;
  height: 32px;
  padding-inline: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.form__password-field:focus-visible {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.form__error {
  color: red;
}

.form__reset {
  color: #1890ff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
}

.form__button {
  color: white;
  background-color: #1890ff;
  border: none;
  cursor: pointer;
  width: 30%;
  height: 32px;
  padding-inline: 10px;
  border-radius: 4px;
  margin-inline: auto;
}

.form__resend {
  border: none;
  background-color: transparent;
  color: black;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

